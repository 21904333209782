import { useQuery } from 'react-query';
import { ApiPageInfo } from '../../types/shopify/commons/PageInfo.type';
import { Edges } from '../../types/shopify/Edges.type';
import { Collection } from '../../types/shopify/products/Collection.type';
import { Product } from '../../types/shopify/products/Product.type';

export type ProductsQuery = {
  products?: Product & { collections: Edges<Collection> };
};

const fetchProducts = async (params?: string) => {
  const res = await window.fetch(`/api/products/${params}`);
  return res.json();
};

const useProductsQuery = (params?: string) =>
  useQuery<{
    pageInfo: ApiPageInfo;
    products: (Product & { collections: Edges<Collection> })[];
  }>(['products', params], () => fetchProducts(params), {
    refetchOnWindowFocus: false,
  });

export default useProductsQuery;
