import { Edges } from '../../../types/shopify/Edges.type';
import { Collection } from '../../../types/shopify/products/Collection.type';
import { Product } from '../../../types/shopify/products/Product.type';
import UplProductCard from '../UplProductCard/UplProductCard';

type Props = {
  list?: (Product & { collections: Edges<Collection> })[];
  category?: string;
  login?: boolean;
};

const UplProducts: React.FC<Props> = ({ list = [], category }) => (
  <>
    {list.map((l) => (
      <UplProductCard item={l} key={l.handle} category={category} />
    ))}
  </>
);

export default UplProducts;
