import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import CollectionsModel, {
  Relation,
} from '../../../models/top/CollectionsModel';
import UplCategoryButton from '../../atoms/UplCategoryButton/UplCategoryButton';

type Props = {
  relations: Relation[];
};

const CategoryMenu = React.memo(
  ({
    title,
    to,
    onClick,
  }: {
    title: string;
    to: string;
    onClick: (to: string) => void;
  }) => {
    const isCampaign = CollectionsModel.isCampaignCollection(title);
    const style = isCampaign
      ? {
          bgColor: 'bg-theme-200 rounded-t',
          hoverBgColor: 'hover:bg-theme-600',
        }
      : { bgColor: 'bg-theme rounded-t', hoverBgColor: 'hover:bg-theme-600' };
    const campaignCollectionProps = CollectionsModel.getCampaignCollectionProps(title);
    const suffix = campaignCollectionProps?.image ? (
      <Image alt={campaignCollectionProps.imgAlt} src={campaignCollectionProps.imgSrc} width={30} height={30} />
    ) : undefined;
    return (
      <div key={title}>
        <UplCategoryButton
          onClick={() => onClick(to)}
          classProps={{
            pl: 'pl-1',
            py: 'py-2',
            bgColor: style.bgColor,
            hoverBgColor: style.hoverBgColor,
            borderColor: `border-border-on-theme ${isCampaign ? 'border-none rounded' : 'border-b-[4px]'}`,
            color: 'ontheme',
          }}
          suffix={suffix}
          description={campaignCollectionProps?.description}
        >
          {title}
        </UplCategoryButton>
      </div>
    );
  },
);
CategoryMenu.displayName = 'CategoryMenu';

const ParentCategoryMenu = React.memo(
  ({
    title,
    to,
    onClick,
  }: {
    title: string;
    to: string;
    onClick: (to: string) => void;
  }) => (
    <div key={title}>
      <UplCategoryButton
        onClick={() => onClick(to)}
        icon='w-[10px] h-[10px] allows-right-gray absolute top-1/3 right-3 sm:top-6 sm:right-8'
        classProps={{
          pl: 'pl-4',
          py: 'py-1',
          borderColor: 'border-gray',
          hoverBgColor: 'hover:bg-white-gray',
          color: 'primary',
          bold: 'medium',
        }}
      >
        {title}
      </UplCategoryButton>
    </div>
  ),
);
ParentCategoryMenu.displayName = 'ParentCategoryMenu';

const ChildCategoryMenu = React.memo(
  ({
    title,
    to,
    onClick,
  }: {
    title: string;
    to: string;
    onClick: (to: string) => void;
  }) => (
    <div key={title}>
      <UplCategoryButton
        onClick={() => onClick(to)}
        classProps={{
          pl: 'pl-1',
          py: 'py-1',
          hoverBgColor: 'hover:bg-white-gray',
          color: 'primary',
          bold: 'medium',
        }}
      >
        {title}
      </UplCategoryButton>
    </div>
  ),
);
ChildCategoryMenu.displayName = 'ParentCategoryMenu';

const UplCategorySearchList: React.FC<Props> = ({ relations }) => {
  const router = useRouter();
  const handleClick = (to: string) => {
    router.push({
      query: { ...router.query, c: to, pi: 0 },
    });
  };

  const allow =
    'after:allows-left-white after:absolute after:top-3 after:-left-[11px] after:content-[""]';

  return (
    <div>
      {relations?.map(({ parent, children }) => (
        <div key={parent.title} className='relative mb-2 shadow-sm'>
          <CategoryMenu
            title={CollectionsModel.getDisplayTitle(parent.title) || ''}
            to={parent.handle || ''}
            onClick={handleClick}
          />
          {children.map((child) => (
            // eslint-disable-next-line tailwindcss/no-custom-classname
            <div key={child.parent.title} className='group relative'>
              <ParentCategoryMenu
                title={
                  CollectionsModel.getDisplayTitle(child.parent.title) || ''
                }
                to={child.parent.handle || ''}
                onClick={handleClick}
              />
              <div
                className={`invisible absolute -top-2 left-64 z-30 w-full rounded border border-gray-200 bg-white p-3 shadow-xl group-hover:visible ${allow}`}
              >
                {child.children.map((cc) => (
                  <ChildCategoryMenu
                    key={cc.parent.title}
                    title={
                      CollectionsModel.getDisplayTitle(cc.parent.title) || ''
                    }
                    to={cc.parent.handle || ''}
                    onClick={handleClick}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default UplCategorySearchList;
