import { NextRouter } from 'next/router';
import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import CollectionsModel, {
  Relation,
} from '../../../models/top/CollectionsModel';
import {
  UplCategoryChildButton,
  UplCategoryGrandParentButton,
  UplCategoryParentButton,
} from '../../atoms/UplCategoryButton/UplCategoryButton';
import UplText from '../../atoms/UplText/UplText';

const ChildAccordion: React.FC<{
  relation: Relation;
  handleClick: (to?: string) => void;
}> = ({ relation, handleClick }) => (
  <UplCategoryChildButton to={relation.parent.handle} onClick={handleClick}>
    <UplText variant='subtext' bold='semibold'>
      {CollectionsModel.getDisplayTitle(relation.parent.title)}
    </UplText>
  </UplCategoryChildButton>
);

const ParentAccordion: React.FC<{
  relation: Relation;
  handleClick: (to?: string) => void;
}> = ({ relation, handleClick }) => (
  <Accordion allowZeroExpanded>
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>
          <UplCategoryParentButton allows='allows-under-black'>
            {CollectionsModel.getDisplayTitle(relation.parent.title)}
          </UplCategoryParentButton>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <ChildAccordion
          relation={{
            ...relation,
            parent: {
              ...relation.parent,
              title: `全ての${CollectionsModel.getDisplayTitle(
                relation.parent.title,
              )}`,
            },
          }}
          handleClick={handleClick}
        />
        {relation.children.map((rel) => (
          <ChildAccordion
            key={rel.parent.title}
            relation={rel}
            handleClick={handleClick}
          />
        ))}
      </AccordionItemPanel>
    </AccordionItem>
  </Accordion>
);

const GrandParentAccordion: React.FC<{
  relations: Relation;
  handleClick: (to?: string) => void;
}> = ({ relations, handleClick }) => (
  <AccordionItem>
    <AccordionItemHeading>
      <AccordionItemButton>
        <UplCategoryGrandParentButton allows='allows-under-black'>
          {CollectionsModel.getDisplayTitle(relations.parent.title)}
        </UplCategoryGrandParentButton>
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <ChildAccordion
        relation={{
          ...relations,
          parent: {
            ...relations.parent,
            title: `全ての${CollectionsModel.getDisplayTitle(
              relations.parent.title,
            )}`,
          },
        }}
        handleClick={handleClick}
      />
      {relations.children.map((relation) => (
        <ParentAccordion
          key={relation.parent.title}
          relation={relation}
          handleClick={handleClick}
        />
      ))}
    </AccordionItemPanel>
  </AccordionItem>
);

const UplAccordionCollection: React.FC<{
  relations?: Relation[];
  router: NextRouter;
}> = React.memo(({ relations, router }) => {
  const handleClick = (to?: string) => {
    router.push({
      pathname: '/',
      query: { ...router.query, c: to },
    });
  };
  return (
    <Accordion allowZeroExpanded>
      {relations?.map((relation) => (
        <GrandParentAccordion
          key={relation.parent.title}
          relations={relation}
          handleClick={handleClick}
        />
      ))}
    </Accordion>
  );
});

UplAccordionCollection.displayName = 'UplAccordionCollection';
export default UplAccordionCollection;
