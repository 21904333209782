import Link from 'next/link';
import { FC, useEffect, useState } from 'react';
import { MdCancel } from 'react-icons/md';
import Modal from 'react-modal';
import BlogArticleModel from '../../../models/blog/BlogArticleModel';
import DateModel from '../../../models/estimate/DateModel';
import { BlogArticle } from '../../../types/shopify/blog/BlogArticle.type';
import { Edges } from '../../../types/shopify/Edges.type';
import UplBarTitle from '../../atoms/UplBarTitle/UplBarTitle';
import UplText from '../../atoms/UplText/UplText';

Modal.setAppElement('body');

interface Props {
  news: {
    articles: Edges<BlogArticle>;
  };
}

const STORE_KEY = 'latestArticlePublishedAt';

const UplBlogModal: FC<Props> = ({ news }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [articles, setArticles] = useState<Edges<BlogArticle>['edges']>([]);

  useEffect(() => {
    const storedLatestArticlePublishedAt = localStorage.getItem(STORE_KEY);
    const latestArticles = !storedLatestArticlePublishedAt
      ? news.articles.edges
      : news.articles.edges.filter((f) =>
          new DateModel(f.node.publishedAt).isAfter(
            storedLatestArticlePublishedAt,
          ),
        );
    if (latestArticles.length) {
      setArticles(latestArticles);
      setIsModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news.articles]);

  const closeModal = () => {
    const latestArticlePublishedAt = news.articles.edges[0].node.publishedAt;
    localStorage.setItem(STORE_KEY, latestArticlePublishedAt);
    setIsModalOpen(false);
  };

  useEffect(
    () => () => {
      closeModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      className={{
        base: 'flex max-h-[90%] max-w-[95%] justify-center rounded-lg bg-white outline-none sm:w-11/12 md:w-2/3 lg:hidden',
        afterOpen: 'animate-slideUp',
        beforeClose: 'animate-slideDown',
      }}
      overlayClassName={{
        base: 'fixed top-0 left-0 h-full w-full bg-[rgba(0,0,0,0.5)] flex justify-center items-center z-40 lg:hidden',
        afterOpen: 'animate-fadeIn',
        beforeClose: 'animate-fadeOut',
      }}
    >
      <div className='h-fit w-11/12 pt-2 '>
        <div className='ml-5 flex justify-between pb-2 '>
          <UplBarTitle>お知らせ</UplBarTitle>
          <button
            onClick={closeModal}
            className='h-min cursor-pointer text-3xl text-gray-200 hover:text-dark-gray'
          >
            <MdCancel />
          </button>
        </div>
        <div className='mx-auto my-1 h-fit w-11/12 overflow-y-auto rounded border border-gray bg-white px-2 py-1 sm:max-h-72 md:max-h-80'>
          <ul>
            {articles.map(({ node }) => (
              <Link
                href={`/notify/detail/${node.handle}`}
                passHref
                key={node.handle}
              >
                <li className='my-1 cursor-pointer rounded border-b border-white-gray p-1 hover:bg-white-gray'>
                  <div className='flex items-end'>
                    {new BlogArticleModel(node).isNew && (
                      <div className='mr-1 w-fit whitespace-nowrap rounded border border-theme bg-theme-100'>
                        <UplText variant='description' color='link'>
                          新着
                        </UplText>
                      </div>
                    )}
                    <div className='line-clamp-1'>
                      <UplText variant='text' color='link' bold='semibold' link>
                        {node.title}
                      </UplText>
                    </div>
                  </div>
                  <UplText variant='description'>
                    {new DateModel(node.publishedAt).formattedDateTime}
                  </UplText>
                  <p className='mx-1 mt-1 line-clamp-3'>
                    <UplText variant='subtext' color='description'>
                      {node.content}
                    </UplText>
                  </p>
                </li>
              </Link>
            ))}
          </ul>
        </div>
        <div className='mb-1 ml-2 text-right'>
          <UplText variant='description' color='link' href='/notify'>
            もっと見る
          </UplText>
        </div>
      </div>
    </Modal>
  );
};

export default UplBlogModal;
