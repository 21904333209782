import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import Image from 'next/image';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Edges } from '../../../types/shopify/Edges.type';
import { BlogArticle } from '../../../types/shopify/blog/BlogArticle.type';

SwiperCore.use([Pagination, Navigation, Autoplay]);

const UplSlideShow: React.FC<{
  ads?: Edges<BlogArticle>;
}> = ({ ads }) => (
  <div className='relative z-0 w-full'>
    {ads ? (
      <Swiper
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={ads.edges.length > 1}
        speed={800}
      >
        {ads.edges.map((i) => (
          <SwiperSlide className='w-full cursor-pointer' key={`${i.node.id}`}>
            <a href={i.node.content} target='_blank' rel='noreferrer'>
              <div className='relative min-h-[250px] w-full min-w-[300px] sm:min-h-[200px] sm:min-w-[240px]'>
                {i.node.image?.url && (
                  <Image
                    src={i.node.image?.url}
                    alt={i.node.title}
                    fill
                    className='object-contain'
                  />
                )}
              </div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    ) : (
      <div />
    )}
  </div>
);

export default UplSlideShow;
