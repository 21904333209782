import { useQuery } from 'react-query';
import CollectionsModel from '../../models/top/CollectionsModel';
import { Collection } from '../../types/shopify/products/Collection.type';

/**
 * [名前] コレクション全検索
 * [概要] 全てのコレクションを検索し、model化する
 */
const useCollections = () =>
  useQuery<CollectionsModel>(
    'collections',
    async () => {
      const res = await fetch('/api/collections/');

      if (res.status !== 200) {
        throw new Error('api fetch error');
      }
      const json = await res.json();
      return new CollectionsModel(json.collections as Collection[]);
    },
    { refetchOnWindowFocus: false },
  );
export default useCollections;
