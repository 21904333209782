import { gql, useQuery } from 'urql';
import { BlogArticle } from '../../types/shopify/blog/BlogArticle.type';
import { Edges } from '../../types/shopify/Edges.type';

const getBlog = gql`
  query GetBlog {
    news: blog(handle: "ニュース") {
      articles(first: 10, sortKey: PUBLISHED_AT, reverse: true) {
        edges {
          node {
            id
            content
            handle
            publishedAt
            title
            image {
              altText
              height
              id
              width
              url
            }
          }
        }
      }
    }
    ad: blog(handle: "広告") {
      articles(first: 10, sortKey: UPDATED_AT, reverse: true) {
        edges {
          node {
            id
            content
            title
            image {
              altText
              height
              id
              width
              url
            }
          }
        }
      }
    }
  }
`;

const useBlog = () =>
  useQuery<{
    news?: {
      articles: Edges<BlogArticle>;
    };
    ad?: {
      articles: Edges<BlogArticle>;
    };
  }>({
    query: getBlog,
  });

export default useBlog;
