/* eslint-disable react/no-array-index-key */
import React, { FC, useMemo } from 'react';
import UplPaginationButton, {
  PaginationButtonText,
} from '../../atoms/UplPaginationButton/UplPaginationButton';

interface UplPaginationProps {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  margin?: string;
  onClickNext: () => void;
  onClickPrev: () => void;
  maxPage: number;
  currentPage: number;
  dataCy?: string;
}

const UplPagination: FC<UplPaginationProps> = ({
  hasNextPage,
  hasPrevPage,
  margin,
  onClickNext,
  onClickPrev,
  maxPage,
  currentPage,
  dataCy,
}) => {
  const paginationButtonTextList: PaginationButtonText[] = useMemo(() => {
    let list: PaginationButtonText[];
    switch (true) {
      case maxPage < 6:
        list = [...Array(maxPage)].map((_, i) => i + 1);
        break;
      case currentPage < 3:
        list = [1, 2, 3, '...', maxPage];
        break;
      case currentPage === 3:
        list =
          maxPage === 6 ? [1, 2, 3, 4, 5, 6] : [1, 2, 3, 4, '...', maxPage];
        break;
      case currentPage === 4:
        list =
          maxPage === 6
            ? [1, 2, 3, 4, 5, 6]
            : [1, 2, 3, 4, 5, maxPage === 7 ? 6 : '...', maxPage];
        break;
      case currentPage + 1 - (maxPage - 1) === 0:
        list = [
          1,
          '...',
          currentPage - 1,
          currentPage,
          currentPage + 1,
          maxPage,
        ];
        break;
      case currentPage + 1 === maxPage:
        list = [1, '...', currentPage - 1, currentPage, maxPage];
        break;
      case currentPage === maxPage:
        list = [1, '...', currentPage - 2, currentPage - 1, maxPage];
        break;
      default:
        list = [
          1,
          '...',
          currentPage - 1,
          currentPage,
          currentPage + 1,
          maxPage - (currentPage + 1) <= 2 ? maxPage - 1 : '...',
          maxPage,
        ];
    }
    return list;
  }, [currentPage, maxPage]);

  const paginationStyle = `${margin} flex justify-center`;
  const prevStyle = `${!hasPrevPage ? 'text-gray-200' : 'hover:bg-gray'} 
    ${currentPage === 1 ? 'border-r-0' : 'border-r-1'} 
    rounded-l-xl border border-gray-200 w-20 h-11 sm:w-14 text-center`;
  const nextStyle = `${!hasNextPage ? 'text-gray-200' : 'hover:bg-gray'} 
    ${currentPage === maxPage ? 'border-l-0' : 'border-l-1'}
    rounded-r-xl border  border-gray-200 shadow w-20 h-11 sm:w-14 text-center`;

  return (
    <div data-testid='UplPagination' className={paginationStyle}>
      <button
        type='button'
        onClick={onClickPrev}
        disabled={!hasPrevPage}
        className={prevStyle}
        data-cy={`${dataCy}-prev-button`}
      >
        <span className='allows-left-gray mr-2 inline-block size-2.5 sm:mr-0' />
        <span className='sm:text-sm'>前へ</span>
      </button>
      {paginationButtonTextList.map((pageNum, i) => (
        <UplPaginationButton
          key={`uplPaginationButton-${i}`}
          pageNumber={pageNum}
          currentPageNumber={currentPage || 0}
        />
      ))}
      <button
        type='button'
        onClick={onClickNext}
        disabled={!hasNextPage}
        className={nextStyle}
        data-cy={`${dataCy}-next-button`}
      >
        <span className='sm:text-sm'>次へ</span>
        <span className='allows-right-gray ml-2 inline-block size-2.5 sm:ml-0' />
      </button>
    </div>
  );
};

export default UplPagination;
