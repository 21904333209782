import { useRouter } from 'next/router';
import React, { FC, useMemo } from 'react';

export type PaginationButtonText = number | '...';
interface UplPaginationButtonProps {
  pageNumber: PaginationButtonText;
  currentPageNumber: number;
}
const getPageNumber = (pageNumber: PaginationButtonText) =>
  typeof pageNumber === 'number' ? pageNumber : 1;
const isThreePointLeader = (pageNumber: PaginationButtonText) =>
  pageNumber === '...';
const UplPaginationButton: FC<UplPaginationButtonProps> = ({
  pageNumber,
  currentPageNumber,
}) => {
  const router = useRouter();
  const style = useMemo(() => {
    const styles: string[] = ['w-11 h-11 sm:w-7 text-center'];
    if (isThreePointLeader(pageNumber)) {
      styles.push('text-gray-200 border-y border-gray-200');
    } else if (currentPageNumber === pageNumber) {
      styles.push('border border-theme  font-bold');
    } else {
      styles.push('border-y border-gray-200 hover:bg-gray');
    }

    return styles.join(' ');
  }, [currentPageNumber, pageNumber]);
  return (
    <button
      className={style}
      disabled={
        isThreePointLeader(pageNumber) || currentPageNumber === pageNumber
      }
      onClick={() =>
        router.push({
          query: { ...router.query, pi: getPageNumber(pageNumber) - 1 },
        })
      }
    >
      <span className='sm:text-sm'>{pageNumber}</span>
    </button>
  );
};

export default UplPaginationButton;
