import { BlogArticle } from '../../types/shopify/blog/BlogArticle.type';
import DateModel from '../estimate/DateModel';

/**
 * 記事の新着期間
 */
const NEW_PERIOD = 14;

export default class BlogArticleModel {
  private data: BlogArticle;

  private dateModel: DateModel;

  constructor(blog: BlogArticle) {
    this.data = blog;
    this.dateModel = new DateModel(blog.publishedAt);
  }

  /**
   * ID
   */
  get id() {
    return this.data.id;
  }

  /**
   * 記事の内容
   */
  get content() {
    return this.data.content;
  }

  /**
   * 記事の内容（HTML形式）
   */
  get contentHtml() {
    return this.data.contentHtml;
  }

  /**
   * 一意の文字列
   */
  get handle() {
    return this.data.handle;
  }

  /**
   * 記事が公開された日時
   */
  get publishedAt() {
    return this.data.publishedAt;
  }

  /**
   * タグ
   */
  get tags() {
    return this.data.tags;
  }

  /**
   * 記事の題名
   */
  get title() {
    return this.data.title;
  }

  /**
   * 記事の関連づけられた画像
   */
  get image() {
    return this.data.image;
  }

  /**
   * 記事が新着である場合はtrueを返す
   */
  get isNew() {
    return this.dateModel.isWithinDays(NEW_PERIOD);
  }

  /**
   * 記事が公開された日時(日本時間)
   */
  get commonFormattedPublishedAt() {
    return this.dateModel.formattedDateTime;
  }
}
