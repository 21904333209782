import React from 'react';
import UplText, { BoldTypes, ColorTypes } from '../UplText/UplText';
import CollectionsModel from '../../../models/top/CollectionsModel';

interface UplCategoryButtonProps {
  children: React.ReactNode;
  allows?: string;
  onClick?: (to?: string) => void;
  to?: string;
}

interface UplCategoryNonLinkButtonProps {
  children: React.ReactNode;
  allows?: string;
}

interface ICategoryButtonTemplateProps {
  children: React.ReactNode;
  onClick?: (to?: string) => void;
  to?: string;
  icon?: string;
  pl?: string;
  classProps: {
    pl?: string;
    py?: string;
    bgColor?: string;
    hoverBgColor?: string;
    color?: ColorTypes;
    bold?: BoldTypes;
    borderColor?: string;
  };
  suffix?: JSX.Element;
  description?: string;
}
const UplCategoryButton: React.FC<ICategoryButtonTemplateProps> = ({
  children,
  onClick,
  to,
  icon,
  classProps: {
    pl = 'pl-5',
    py = 'py-4',
    bgColor = 'bg-white',
    hoverBgColor = 'hover:bg-gray',
    color = 'primary',
    bold = 'semibold',
    borderColor = 'border-white-gray',
  },
  suffix,
  description,
}) => (
  <button
    type='button'
    onClick={() => onClick && onClick(to)}
    className={`relative flex flex-wrap items-center ${py} ${pl} text-left ${color} ${bgColor} ${hoverBgColor} border-b ${borderColor} w-full cursor-pointer`}
  >
    <p className='p-1 align-middle text-sm'>
      <UplText variant='subtext' bold={bold} color={color}>
        {children}
      </UplText>
    </p>
    {suffix}
    {description && (
      <div className='after:allows-left-white-small relative ml-[13px] rounded-md bg-white px-2 py-1 after:absolute after:left-[-6px] after:top-[11px]'>
        <UplText variant='description' color='primary' bold='bold'>
          {description}
        </UplText>
      </div>
    )}
    <span className={icon} />
  </button>
);

export const UplCategoryChildButton: React.FC<UplCategoryButtonProps> = ({
  children,
  allows = 'allows-right-gray',
  onClick,
  to,
}) => {
  const icon = `lg:allows-right-gray w-[10px] h-[10px] ${allows} absolute top-1/3 right-6 sm:top-6 sm:right-8`;
  return (
    <UplCategoryButton
      onClick={onClick}
      to={to}
      icon={icon}
      classProps={{ pl: 'pl-7' }}
    >
      {children}
    </UplCategoryButton>
  );
};

export const UplCategoryParentButton: React.FC<
  UplCategoryNonLinkButtonProps
> = ({ children, allows = 'allows-under-black' }) => {
  const icon = `lg:allows-right-gray w-[10px] h-[10px] ${allows} absolute top-1/2 right-4 sm:top-6 sm:right-8`;
  return (
    <UplCategoryButton
      icon={icon}
      classProps={{
        pl: 'pl-5',
        bgColor: 'bg-theme-100',
        hoverBgColor: 'hover:bg-theme-200',
        borderColor: 'border-theme-200',
        color: 'primary',
      }}
    >
      {children}
    </UplCategoryButton>
  );
};

export const UplCategoryGrandParentButton: React.FC<
  UplCategoryNonLinkButtonProps
> = ({ children, allows = 'allows-under-black' }) => {
  const icon = `lg:allows-right-gray w-[10px] h-[10px] ${allows} absolute top-1/2 right-4 sm:top-6 sm:right-8`;
  const displayName = typeof children === 'string' ? children : '';
  const isCampaign = CollectionsModel.isCampaignCollection(displayName);
  const campaignCollectionProps =
    CollectionsModel.getCampaignCollectionProps(displayName);

  const bg = isCampaign
    ? {
        bgColor: 'bg-theme-200',
        hoverBgColor: 'hover:bg-theme-600',
      }
    : { bgColor: 'bg-theme', hoverBgColor: 'hover:bg-theme-600' };
  return (
    <UplCategoryButton
      icon={icon}
      classProps={{
        pl: 'pl-3',
        bgColor: bg.bgColor,
        hoverBgColor: bg.hoverBgColor,
        borderColor: 'border-theme-700',
        color: 'primary',
      }}
      description={campaignCollectionProps?.description}
    >
      {children}
    </UplCategoryButton>
  );
};

const CategoryMenu = React.memo(
  ({
    title,
    to,
    onClick,
  }: {
    title: string;
    to: string;
    onClick: (to: string) => void;
  }) => (
    <div key={title}>
      <UplCategoryButton
        onClick={() => onClick(to)}
        classProps={{
          pl: 'pl-1',
          py: 'py-2',
          bgColor: 'bg-theme',
          hoverBgColor: 'hover:bg-theme-700',
          borderColor: 'border-theme-700',
          color: 'primary',
        }}
      >
        {title}
      </UplCategoryButton>
    </div>
  ),
);
CategoryMenu.displayName = 'CategoryMenu';

export default UplCategoryButton;
