import type { GetServerSidePropsContext, NextPage } from 'next';
import Head from 'next/head';
import React, { FC, useEffect, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useRouter } from 'next/router';
import Link from 'next/link';
import UplTwoColumnContainer from '../components/layout/UplTwoColumnContainer/UplTwoColumnContainer';
import UplFooter from '../components/layout/UplFooter/UplFooter';
import UplHeader from '../components/layout/UplHeader/UplHeader';
import UplDepotFooter from '../components/molecules/UplDepotFooter/UplDepotFooter';
import UplDepotHeader from '../components/molecules/UplDepotHeader/UplDepotHeader';
import UplProducts from '../components/molecules/UplProducts/UplProducts';
import UplSlideShow from '../components/molecules/UplSlideShow/UplSlideShow';
import UplCategorySearchList from '../components/molecules/UplCategorySearchList/UplCategorySearchList';
import UplBarTitle, {
  UplMenubarTitle,
} from '../components/atoms/UplBarTitle/UplBarTitle';
import useCollections from '../hooks/top/useCollections';
import UplPagination from '../components/molecules/UplPagination/UplPagination';
import UplProductsSkeleton from '../components/molecules/UplProductsSkeleton/UplProductsSkeleton';
import useProductsQuery from '../hooks/top/useQueryProducts';
import UplPriceRangeSelects from '../components/molecules/UplPriceRangeSelects/UplPriceRangeSelects';
import UplAccordionCollection from '../components/molecules/UplAccordionCollection/UplAccordionCollection';
import UplText from '../components/atoms/UplText/UplText';
import UplWrapper from '../components/layout/UplWrapper/UplWrapper';
import DateModel from '../models/estimate/DateModel';
import SearchBadges from '../components/molecules/UplSearchBadges/UplSearchBadges';
import { UplVendorSearchBox } from '../components/molecules/UplSearchBox/UplSearchBox';
import { SORTVALUE } from '../constants/ProductsConstants';
import UplSortSelect from '../components/molecules/UplSortSelect/UplSortSelect';
import useBlog from '../hooks/top/useBlog';
import UplSpinner from '../components/atoms/UplSpinner/UplSpinner';
import UplBlogModal from '../components/molecules/UplBlogModal/UplBlogModal';
import BlogArticleModel from '../models/blog/BlogArticleModel';

type QueryProps = {
  query: {
    c?: string;
    sw?: string;
    v?: string;
    min?: string;
    max?: string;
    pi?: string;
    order?: string;
  };
  count?: number;
};

type TopProps = {
  customerAccessToken: string;
} & QueryProps;

const ProductGrid: FC<{ children: React.ReactNode }> = ({ children }) => (
  <div
    className='mt-6 grid place-items-center sm:mt-2 sm:grid-cols-2 sm:gap-4 xs:mx-8 xs:grid-cols-1 xs:gap-4 md:grid-cols-3 md:gap-6 lg:grid-cols-4 lg:gap-4'
    data-cy='product-container'
  >
    {children}
  </div>
);

const Top: NextPage<TopProps> = ({ query }) => {
  const router = useRouter();

  const { data: collections, isFetching: collectionFetching } =
    useCollections();
  const [{ data: blogs, fetching: blogFetching }] = useBlog();
  const alert = useAlert();
  const {
    data,
    error,
    isFetching: productFetching,
  } = useProductsQuery(router.asPath);

  const existProducts = useMemo(
    () => (data?.products?.length || 0) > 0,
    [data],
  );

  useEffect(() => {
    if (!data?.products) {
      if (error) {
        alert.error('商品の取得に失敗しました。');
      }
    }
  }, [data?.products, error, alert]);

  const handlePrevPage = () => {
    router.push({ query: { ...router.query, pi: Number(query.pi || 0) - 1 } });
  };

  const handleNextPage = () => {
    router.push({ query: { ...router.query, pi: Number(query.pi || 0) + 1 } });
  };

  const { order, pi, ...searchQueries } = query;
  const existsQuery = !!Object.values(searchQueries).join('');
  const loading = productFetching || collectionFetching || blogFetching;

  return (
    <UplWrapper>
      <Head>
        <title>建deポーター</title>
        <meta name='description' content='建deポーター' />
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <UplHeader>
        <UplDepotHeader>
          <div className='mt-4 rounded' data-cy='vendor-search'>
            <UplMenubarTitle>メーカーから探す</UplMenubarTitle>
            <div className='mt-2 px-4'>
              <UplVendorSearchBox
                onClick={(searchWord) =>
                  router.push({
                    pathname: '/',
                    query: { ...router.query, v: searchWord, pi: 0 },
                  })
                }
                dataCy='sm-md'
              />
            </div>
          </div>
          <div className='mt-4' data-cy='price-range-search'>
            <UplMenubarTitle>価格の絞り込み</UplMenubarTitle>
            <div className='px-4'>
              <UplPriceRangeSelects
                margin='mt-2'
                query={query}
                router={router}
                dataCy='sm-md'
              />
            </div>
          </div>
          <div className='mt-4' data-cy='category-search'>
            <UplMenubarTitle>カテゴリから探す</UplMenubarTitle>
            <UplAccordionCollection
              relations={collections?.relations}
              router={router}
            />
          </div>
        </UplDepotHeader>
      </UplHeader>
      <UplTwoColumnContainer>
        <div
          className='w-full rounded-sm bg-white-gray-200 p-2'
          data-cy='category-list-container'
        >
          <div className='mb-12 rounded'>
            <UplBarTitle>メーカーから探す</UplBarTitle>
            <div className='mt-4'>
              <UplVendorSearchBox
                onClick={(searchWord) =>
                  router.push({
                    pathname: '/',
                    query: { ...router.query, v: searchWord, pi: 0 },
                  })
                }
                dataCy='lg'
              />
            </div>
          </div>
          <div className='mb-12'>
            <UplBarTitle>価格の絞り込み</UplBarTitle>
            <UplPriceRangeSelects
              margin='mt-4'
              query={query}
              router={router}
              dataCy='lg'
            />
          </div>
          <div>
            <UplBarTitle>カテゴリから探す</UplBarTitle>
            <div className='mt-4 w-full px-2'>
              {collections?.relations?.length !== 0 ? (
                <UplCategorySearchList
                  relations={collections?.relations || []}
                />
              ) : (
                <div className='h-[410px] w-full animate-pulse bg-gray' />
              )}
            </div>
          </div>
        </div>
        <div>
          {!existsQuery && (
            <div className='mb-5 grid grid-cols-5 gap-5'>
              <div
                className='col-span-3 flex size-full  items-center sm:col-span-5 md:col-span-5'
                data-cy='ad-container'
              >
                <UplSlideShow ads={blogs?.ad?.articles} />
              </div>
              {blogs?.news &&
                (blogs?.news?.articles.edges?.length || 0) > 0 && (
                  <UplBlogModal news={blogs?.news} />
                )}
              <div
                className='col-span-2 sm:hidden md:hidden'
                data-cy='notify-container'
              >
                <UplBarTitle>お知らせ</UplBarTitle>
                <div className='mt-2 h-fit max-h-40 w-full overflow-y-auto rounded border border-gray bg-white px-2 py-1'>
                  <ul>
                    {(blogs?.news?.articles.edges?.length || 0) === 0 && (
                      <UplText variant='subtext'>お知らせはありません</UplText>
                    )}
                    {blogs?.news?.articles.edges?.map((article) => (
                      <Link
                        href={`/notify/detail/${article.node.handle}`}
                        passHref
                        key={article.node.handle}
                      >
                        <li className='my-1 cursor-pointer rounded border-b border-white-gray p-1 hover:bg-white-gray'>
                          <div className='flex items-end'>
                            {new BlogArticleModel(article.node).isNew && (
                              <div className='mr-1 w-fit whitespace-nowrap rounded border border-theme bg-theme-100'>
                                <UplText variant='description' color='link'>
                                  新着
                                </UplText>
                              </div>
                            )}
                            <div className='line-clamp-1'>
                              <UplText
                                variant='text'
                                color='link'
                                bold='semibold'
                                link
                              >
                                {article.node.title}
                              </UplText>
                            </div>
                          </div>
                          <UplText variant='description'>
                            {
                              new DateModel(article.node.publishedAt)
                                .formattedDateTime
                            }
                          </UplText>
                          <p className='mx-1 mt-1 line-clamp-1'>
                            <UplText variant='subtext' color='description'>
                              {article.node.content}
                            </UplText>
                          </p>
                        </li>
                      </Link>
                    ))}
                  </ul>
                </div>
                <div className='ml-2 text-right'>
                  <UplText variant='description' color='link' href='/notify'>
                    もっと見る
                  </UplText>
                </div>
              </div>
            </div>
          )}
          <div className='flex w-full items-end justify-between sm:flex-col sm:items-start'>
            {existsQuery ? (
              <div className='sm:px-2'>
                <SearchBadges
                  count={data?.pageInfo.total}
                  onChange={(searchWords) => {
                    router.push({
                      pathname: '/',
                      query: {
                        ...searchWords,
                        c: collections?.getHandleByTitle(searchWords.c),
                        order: query.order,
                        pi: 0,
                      },
                    });
                  }}
                  onClear={() => {
                    router.push('/');
                  }}
                  searchWords={{
                    sw: searchQueries.sw,
                    v: searchQueries.v,
                    min: searchQueries.min,
                    max: searchQueries.max,
                    c: collections?.getTitleByHandle(searchQueries.c),
                  }}
                />
              </div>
            ) : (
              <div className='px-2' data-cy='recommend-product-title'>
                <UplText variant='h1'>おすすめ</UplText>
                <UplText variant='text'>の商品</UplText>
              </div>
            )}

            <div className='sm:mt-3' data-cy='sort-select-container'>
              <UplSortSelect
                list={SORTVALUE}
                value={query.order || ''}
                onChange={(value) => {
                  router.push({
                    pathname: '/',
                    query: { ...searchQueries, order: value },
                  });
                }}
              />
            </div>
          </div>
          {loading && <UplSpinner />}
          {loading && !existProducts && (
            <ProductGrid>
              <UplProductsSkeleton />
              <UplProductsSkeleton />
              <UplProductsSkeleton />
              <UplProductsSkeleton />
              <UplProductsSkeleton />
            </ProductGrid>
          )}
          {existProducts ? (
            <>
              <ProductGrid>
                <UplProducts list={data?.products} category={searchQueries.c} />
              </ProductGrid>
              <UplPagination
                hasNextPage={!!data?.pageInfo?.hasNextPage}
                hasPrevPage={!!data?.pageInfo?.hasPrevPage}
                onClickNext={handleNextPage}
                onClickPrev={handlePrevPage}
                margin='mt-10'
                maxPage={data?.pageInfo.maxPage || 1}
                currentPage={(data?.pageInfo.current || 0) + 1}
              />
            </>
          ) : (
            !loading && (
              <p className='mt-4 text-red'>
                <UplText
                  variant='text'
                  color='danger'
                  bold='semibold'
                  dataCy='no-item-message'
                >
                  一致する商品はありませんでした。
                </UplText>
              </p>
            )
          )}
        </div>
      </UplTwoColumnContainer>
      <UplFooter>
        <UplDepotFooter />
      </UplFooter>
    </UplWrapper>
  );
};

export async function getServerSideProps({ query }: GetServerSidePropsContext) {
  return {
    props: { query },
  };
}

export default Top;
